/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";

import styled from "@emotion/styled";
import { Anchor, NavigationLink } from "@/components/Navigation/NavigationLink";
import Image from "next/image";

import { Icon } from "@fllite/ui/components";
import { Logo } from "@fllite/ui/components";
import { Text } from "@fllite/ui/components";

import { SubscriptionForm } from "./SubscriptionForm";

const Container = styled("div")`
  background-color: ${({ theme }) => (theme as any).colors.primaryDarkest};
  position: relative;
`;

const Content = styled("div")`
  max-width: ${({ theme }) => (theme as any).dimensions.pageWidth};
  margin: 0 auto;
  padding: 32px 20px 26px;
`;

const TopContent = styled("div")`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const BottomContent = styled("div")`
  text-align: center;
  opacity: 0.75;
  margin-top: 25px;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    display: flex;
    flex-direction: column;
  }
`;

const Separator = styled("div")`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => (theme as any).colors.primaryLight};
  opacity: 0.25;
`;

const LogoStyled = styled(Logo)`
  flex: 1;

  svg {
    width: 103px;
  }
`;

const Navigation = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  ${Anchor} {
    margin-right: 30px;

    :last-child {
      margin-right: 0;
    }
  }

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 0;
    width: 100%;
    margin-left: 69px;
  }
`;
const NBAA = styled("div")`
  flex: 1;
  margin-top: 20px;
  flex-grow: 0;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    text-align: right;
    margin-top: -10px;
  }
`;

const PixabayLogo = styled(Icon)`
  flex-grow: 0;
  margin-right: 13px;
  svg {
    width: 82px;
  }
`;

const UpperLine = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: left;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const LowerLine = styled("div")`
  display: flex;
  justify-content: flex-start;
  text-align: center;
  margin-top: 10px;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    margin-top: 0px;
  }
`;

const CopyWrapper = styled("div")`
  margin-top: 10px;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    margin-top: 0px;
  }
`;

const Logos = styled("div")`
  display: flex;
  flex-direction: column;
`;

const NBAALogo = styled(Icon)`
  flex-grow: 0;

  svg {
    width: 127px;
  }
`;

const PaymentMethods = styled("div")`
  width: 254px;
  height: 32px;
  margin: 16px 0;
`;

const year = new Date().getFullYear();

const isProd = process.env.NODE_ENV === "production";

export const PageFooter: FC<{ hasSubscriptionForm?: boolean }> = ({
  hasSubscriptionForm = true,
}) => (
  <Container data-testid="footer">
    <Content>
      {hasSubscriptionForm && <SubscriptionForm />}
      <TopContent>
        <NavigationLink href="/" dataTestid="logo-footer">
          <LogoStyled />
        </NavigationLink>

        <Navigation>
          <NavigationLink href="/contact-us" dataTestid="footer-contact">
            Contact Us
          </NavigationLink>
          <NavigationLink href="/privacy" dataTestid="footer-privacy">
            Privacy
          </NavigationLink>
          <NavigationLink href="/terms" dataTestid="footer-terms">
            Terms & Conditions
          </NavigationLink>
        </Navigation>
        {isProd ? (
          <NBAA>
            <NBAALogo type="nbaa" />
          </NBAA>
        ) : (
          <Logos>
            <NBAA>
              <NBAALogo type="nbaa" />
            </NBAA>
            <PaymentMethods>
              <Image
                src="/assets/payment-methods-universal.webp"
                width={256}
                height={32}
                alt="Payment methods"
              />
            </PaymentMethods>
          </Logos>
        )}
      </TopContent>

      <Separator />
      <BottomContent>
        <UpperLine>
          <Text data-testid="bottom-content-1" subtext>
            Fllite is not an air carrier. Fllite acts as an agent for our
            members to coordinate aircraft from FAA/DOT approved operators.
          </Text>
          <CopyWrapper>
            <Text data-testid="bottom-content-2" subtext>
              © {year} Fllite Inc. All rights reserved.
            </Text>
          </CopyWrapper>
        </UpperLine>
        <LowerLine>
          <PixabayLogo type="pixabay-white" />
          <Text data-testid="bottom-content-3" subtext>
            Photos sourced by Pixabay.
          </Text>
        </LowerLine>
      </BottomContent>
    </Content>
  </Container>
);
