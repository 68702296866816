/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "@emotion/styled";
import Link from "next/link";

import { getContextForApp } from "@fllite/shared";
import { useAuthentication } from "@fllite/ui/hooks";
import { StyledButton } from "@fllite/ui/components";
import { Icon } from "@fllite/ui/components";
import { UserBox } from "@fllite/ui/components";

import { Anchor } from "./NavigationLink";
import { forwardRef } from "react";

const NavigationLinkList = styled("div")`
  display: flex;

  ${Anchor} {
    display: flex;
    margin-left: 30px;
  }
`;

const DesktopNavigation = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

interface NavigationProps {}
const NavigationWrap = styled("div")<NavigationProps>`
  height: 100%;
`;

const IconPerson = styled(Icon)`
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  color: white;
  padding-bottom: 2px;
`;

const BaseUpdatedButton = styled(StyledButton)`
  padding: 2px 20px;
  min-height: 32px;
  min-width: 93px;
  font-size: 16px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  &:hover ${IconPerson} {
    color: ${({ theme }) => (theme as any).colors.primary};
  }
`;

type BaseUpdatedButtonProps = React.ComponentProps<typeof BaseUpdatedButton>;

const UpdatedButton = forwardRef<HTMLButtonElement, BaseUpdatedButtonProps>(
  (props, ref) => <BaseUpdatedButton ref={ref} {...props} />,
);

const RegisterButton = styled(UpdatedButton)`
  background: linear-gradient(92.58deg, #2077e0 0%, #004ba6 100%);
`;

export const DesktopView = () => {
  const { appUrl } = getContextForApp();
  const { user } = useAuthentication();
  const isAuthenticated = user !== false && user != null;

  const unauthenticatedNavigationContent = (
    <DesktopNavigation>
      <Link
        href={user !== false && user != null ? appUrl ?? "" : `${appUrl}/login`}
        passHref
        legacyBehavior
      >
        <UpdatedButton
          outline
          data-testid="navigation-link-sign-in"
          as="a"
          type={null as any}
        >
          <IconPerson type="person" />
          Log in
        </UpdatedButton>
      </Link>
      <Link
        href={
          user !== false && user != null ? appUrl ?? "" : `${appUrl}/register`
        }
        passHref
        legacyBehavior
      >
        <RegisterButton
          data-testid="navigation-link-register"
          as="a"
          type={"button"}
        >
          Create account
        </RegisterButton>
      </Link>
    </DesktopNavigation>
  );

  const authenticatedNavigationContent = (
    <DesktopNavigation>
      <Link href={appUrl!} passHref legacyBehavior>
        <UpdatedButton outline as="a" type={null as any}>
          Go to Dashboard
        </UpdatedButton>
      </Link>
      <UserBox isHomepage />
    </DesktopNavigation>
  );

  return (
    <NavigationWrap>
      <NavigationLinkList>
        {isAuthenticated
          ? authenticatedNavigationContent
          : unauthenticatedNavigationContent}
      </NavigationLinkList>
    </NavigationWrap>
  );
};
