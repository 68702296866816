/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFormContext } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";

import { useApiNewsletterPost } from "@fllite/api";
import { useFlashMessage } from "@fllite/ui/components";
import { H3 } from "@fllite/ui/components";

import { schema } from "./validationSchema";

const Container = styled("div")`
  text-align: center;
  margin-top: 52px;
  margin-bottom: 120px;
`;

const ErrorMessage = styled("div")`
  color: ${({ theme }) => (theme as any).colors.danger};
`;

const Button = styled("button")`
  border: none;
  background: ${({ theme }) => (theme as any).colors.secondary}
    ${({ theme }) => (theme as any).gradients.secondary};
  border-radius: ${({ theme }) => (theme as any).radius.buttonRadius};
  color: ${({ theme }) => (theme as any).colors.baseFontColor};
  font-size: 18px;
  font-weight: 500;
  height: 52px;
  padding: 0 20px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  max-width: 521px;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    border-radius: 0 ${({ theme }) => (theme as any).radius.buttonRadius}
      ${({ theme }) => (theme as any).radius.buttonRadius} 0;
    margin-top: 0;
    width: auto;
  }
`;

const TextField = () => (
  <TextFieldElement
    name="email"
    placeholder="Enter your email address"
    data-testid="footer-subscription-form-input"
    sx={(theme: any) => ({
      width: "100%",
      maxWidth: "521px",
      height: "52px",
      "& .MuiInput-root:before": {
        borderBottom: 0,
      },
      "& .MuiInput-root:after": {
        borderBottomWidth: 0,
      },
      "&  .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "none",
        },
        borderRadius: (theme as any).radius.buttonRadius,
        backgroundColor: (theme as any).colors.primaryDark,
        py: "0px",
        paddingLeft: "5px",

        [(theme as any).breakpoints.up("md")]: {
          borderRadius: `${(theme as any).radius.buttonRadius} 0 0 ${(theme as any).radius.buttonRadius}`,
        },
      },
    })}
    inputProps={{
      sx: (theme: any) => ({
        py: "14.5px",
        color: "white",
        fontSize: "16px",
        "&::placeholder": {
          color: (theme as any).colors.primaryLight,
          fontSize: "16px",
          opacity: 1,
        },
      }),
    }}
    parseError={() => ""}
  />
);

const FormError = () => {
  const {
    formState: { errors },
  } = useFormContext();

  if (errors?.email) {
    return (
      <ErrorMessage data-testid="footer-subscription-error-message">
        <>{errors.email.message}</>
      </ErrorMessage>
    );
  }

  return null;
};

export const SubscriptionForm = () => {
  const { FlashMessageTypes, pushFlashMessage } = useFlashMessage();

  const { mutate: subscribe } = useApiNewsletterPost({
    mutation: {
      onSuccess: () => {
        pushFlashMessage({
          type: FlashMessageTypes.Success,
          text: "Your e-mail was added to the newsletter list.",
        });
      },
      onError: () => {
        pushFlashMessage({
          type: FlashMessageTypes.Error,
          text: "There was an error while subscribing our Newsletter.",
        });
      },
    },
  });

  const handleFormSubmit = async (data: { email: string }) => {
    subscribe({ data });
  };

  const formContext = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Container>
      <H3 data-testid="h3-footer">Get the Latest News from Fllite</H3>
      <FormContainer
        onSuccess={handleFormSubmit}
        formContext={formContext}
        FormProps={{ style: { marginTop: "37px" } }}
      >
        <TextField />
        <Button data-testid="footer-subscription-form-subscribe">
          Subscribe
        </Button>
        <FormError />
      </FormContainer>
    </Container>
  );
};
