import { FC, ReactNode } from "react";

import styled from "@emotion/styled";
import { Navigation } from "@/components/Navigation";

import { PageFooter } from "./PageFooter";

interface ContainerProps {
  hasBackground?: boolean;
  hasNavigation?: boolean;
  hasFooter?: boolean;
  useAlternativeBackground?: boolean;
}

const Container = styled("div")<ContainerProps>`
  background: ${({ theme, hasBackground, useAlternativeBackground }) =>
    useAlternativeBackground
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (theme as any).backgrounds.thirdGradient
      : hasBackground
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (theme as any).backgrounds.primaryGradient
        : "transparent"};
`;

interface PageProps {
  children: ReactNode;
  hasBackground?: boolean;
  hasNavigation?: boolean;
  hasFooter?: boolean;
  hasNavigationBackground?: boolean;
  useAlternativeBackground?: boolean;
  hasSubscriptionForm?: boolean;
}

export const Page: FC<PageProps> = ({
  children,
  hasBackground = true,
  hasNavigation = true,
  hasFooter = true,
  hasNavigationBackground,
  useAlternativeBackground,
  hasSubscriptionForm = true,
  ...rest
}) => (
  <Container
    hasBackground={hasBackground}
    useAlternativeBackground={useAlternativeBackground}
    {...rest}
  >
    {hasNavigation && <Navigation hasBackground={hasNavigationBackground} />}
    {children}
    {hasFooter && <PageFooter hasSubscriptionForm={hasSubscriptionForm} />}
  </Container>
);
