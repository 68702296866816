/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from "react";

import styled from "@emotion/styled";
import { Box, Drawer, List, ListItem, ListItemButton } from "@mui/material";
import Link from "next/link";

import { getContextForApp } from "@fllite/shared";
import { useAuthentication } from "@fllite/ui/hooks";
import { UserBox } from "@fllite/ui/components";

interface NavigationProps {
  isOpen: boolean;
}

const Line = styled("div")`
  width: 24px;
  height: 2px;
  border-radius: 12px;
  margin: 4px 0;
  transition: 0.4s;
`;

const Burger = styled("div")<NavigationProps>`
  display: inline-block;
  cursor: pointer;
  position: ${({ isOpen }) => (isOpen ? "fixed" : "relative")};
  right: ${({ isOpen }) => (isOpen ? "20px" : "auto")};
  top: ${({ isOpen }) => (isOpen ? "38px" : "auto")};
  z-index: 30;
  padding: 10px;
  margin: -10px;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    display: none;
  }

  ${Line} {
    background-color: ${({ isOpen }) => (isOpen ? "#4183c4" : "white")};
    &:first-of-type {
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(-45deg) translate(-3px, 3px)" : "none"};
    }

    &:nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
    }

    &:last-child {
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(45deg) translate(-6px, -6px)" : "none"};
    }
  }
`;

const MenuButton = styled(ListItemButton)`
  font-size: 16px;
  color: ${({ theme }) => (theme as any).colors.primary};
  text-decoration: none;
`;

const Container = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserBoxWrapper = styled("div")`
  margin-right: 32px;
`;

export const MobileView = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleBurgerClick = () => {
    setIsOpen(!isOpen);
  };
  const { appUrl } = getContextForApp();
  const { user } = useAuthentication();
  const isAuthenticated = !!user;

  const authenticatedMobileLinks = [
    {
      id: "dashboard",
      title: "Go to Dashboard",
      href: appUrl,
      dataTestid: "navigation-dashboard",
    },
  ];

  const unauthenticatedMobileLinks = [
    {
      id: "login",
      title: "Log in",
      href: `${appUrl}/login`,
      dataTestid: "navigation-link-sing-in",
    },
    {
      id: "register",
      title: "Create account",
      href: `${appUrl}/register`,
      dataTestid: "navigation-link-create-account",
    },
    {
      id: "contact",
      title: "Contact us",
      href: "/contact-us",
      dataTestid: "navigation-link-contact",
    },
    {
      id: "terms",
      title: "Terms & Conditions",
      href: "/terms",
      dataTestid: "footer-terms",
    },
  ];

  const burgerLinks = isAuthenticated
    ? authenticatedMobileLinks
    : unauthenticatedMobileLinks;

  const mobileLinks = useMemo(() => {
    return burgerLinks;
  }, [appUrl, isAuthenticated]);

  return (
    <Container>
      {isAuthenticated && (
        <UserBoxWrapper>
          <UserBox isHomepage />
        </UserBoxWrapper>
      )}
      <Burger
        data-testid="Burger-button"
        onClick={handleBurgerClick}
        {...{ isOpen }}
      >
        <Line />
        <Line />
        <Line />
      </Burger>
      <Drawer open={isOpen} onClose={() => setIsOpen(false)} anchor="right">
        <Burger
          data-testid="Burger-button"
          onClick={handleBurgerClick}
          {...{ isOpen }}
        >
          <Line />
          <Line />
          <Line />
        </Burger>
        <Box sx={{ marginTop: "68px" }}>
          <List>
            {mobileLinks.map((menuItem) => (
              <ListItem key={menuItem.id}>
                <Link passHref href={menuItem.href!} legacyBehavior>
                  <MenuButton>{menuItem.title}</MenuButton>
                </Link>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Container>
  );
};
